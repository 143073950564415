.container {
    position: relative;
    min-height: 100svh;
    background: var(--page-background);
    transition: background-color 0.2s ease-out;

    --sidebar-width: 100%;

    .urbania {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        opacity: 0;
    }

    .inner {
        padding: 0 var(--side-spacing);
        transition: padding 0.2s ease-out;
    }

    .title {
        margin: 0;
    }

    .label {
        margin: 0;
        margin-bottom: 10px;
    }

    .metadata {
        display: flex;
        max-width: 600px;
        align-items: center;
        margin-bottom: 10px;

        .label {
            width: 5em;
            margin: 0;
            margin-left: 10px;
        }

        .input {
            display: block;
            width: 100%;
            padding-right: 2em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .utmBuilder {
        display: flex;
        flex-direction: column;

        .form {
            width: 100%;
            max-width: 400px;
            margin-right: 10px;
        }

        .preview {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-top: 20px;
        }

        .field {
            position: relative;
            width: 100%;
            max-width: 400px;
            margin: auto;
        }

        .textarea {
            display: block;
            width: 100%;
            height: 200px;
            word-break: break-all;
        }
    }

    .header {
        margin-bottom: 20px;
    }

    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        .label {
            margin-right: 10px;
            margin-bottom: 0;
        }

        .actions {
            margin-left: auto;
        }
    }

    .actions {
        .button {
            margin-right: 0.5em;
            font-size: 0.85em;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .section {
        padding-bottom: 10px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 60px;

        > .top {
            margin-bottom: 20px;

            .actions {
                margin-left: 10px;
            }
        }

        &:last-child {
            padding-bottom: 60px;
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    .format {
        margin-bottom: 40px;

        .label {
            font-size: 1.25em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .variations {
        display: block;
    }

    .variation {
        max-width: 400px;
        margin-bottom: 20px;

        .label {
            font-size: 1em;
        }

        &.isEditing {
            position: fixed;
            z-index: 2;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            max-width: none;
            height: 100%;
            justify-content: center;
            background: #fff;

            .top {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 10px;
                background: rgba(0, 0, 0, 0.1);
            }

            .center {
                margin: auto;
            }

            .frame {
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                cursor: grab;
            }

            .editButton {
                display: none;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .ratio {
        position: relative;
    }

    .scale {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        --side-spacing: 4%;
        --card-spacing-surtitle: 0.2em;
        --card-font-size-featured-surtitle: 0.4em;
        --card-font-size-featured-description: 0.4em;
        --card-font-size-featured-author: 0.3em;
    }

    .sidebar {
        position: fixed;
        z-index: 3;
        top: 0;
        right: 0;
        width: var(--sidebar-width);
        height: 100%;
        padding: 20px 10px;
        background: #fff;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        transform: translateX(100%);
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow-y: auto;
        scrollbar-width: none;
        transition:
            transform 0.2s ease-out,
            box-shadow 0.2s ease-out;
    }

    .field {
        position: relative;
        width: 100%;
    }

    .formControl {
        padding-bottom: 10px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;

        &.row {
            display: flex;
            flex-direction: row;
            align-items: center;

            --control-label-font-size: 1em;

            label {
                margin-right: auto;
            }

            .field {
                width: 60%;
            }
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    .copyButton {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 2.5em;
        height: 100%;
        padding: 0;
        color: #000;
        font-size: 1em;
    }

    .toggleButton {
        font-size: 1.5em;
    }

    .closeButton {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        padding: 5px;
    }

    &.sidebarOpened {
        .sidebar {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            transform: translateX(0);
        }
    }

    @media (--small-viewport) {
        --sidebar-width: 300px;

        .utmBuilder {
            flex-direction: row;

            .preview {
                margin-top: 0;
            }
        }

        .variations {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        }

        .variation {
            margin: 0;

            &.isEditing {
                width: calc(100% - var(--sidebar-width));

                .frame {
                    width: 400px;
                }
            }
        }
    }

    @media (--small-viewport) {
        --sidebar-width: 400px;
    }

    @media (--large-viewport) {
        .inner {
            padding-right: calc(var(--sidebar-width) + var(--side-spacing));
        }
    }
}
