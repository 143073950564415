.container {
    position: relative;

    .color {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        width: 1.5em;
        height: 1.5em;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    .input {
        width: 10em;
    }

    .picker {
        position: absolute;
        z-index: 2;
        top: 100%;
        right: 0;
        display: none;
        margin-top: 5px;
    }

    &.opened{
        .picker {
            display: block;
        }

    }
}
