.container {
    position: relative;

    .frame {
        position: relative;
    }

    .card,
    .scale {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .card {
        --side-spacing: 4%;
        --card-spacing-surtitle: 0.2em;
        --card-font-size-featured-surtitle: 0.4em;
        --card-font-size-featured-description: 0.4em;
        --card-font-size-featured-author: 0.3em;
    }
}
